<template>
  <v-row justify="center">
    <v-dialog 
      v-model="dialog"
      :fullscreen="true"
      transition="dialog-bottom-transition"
      :scrollable="false"
      :persistent="false"
    >
        <v-card>
          <v-toolbar color="#e0a677" tile style="position: sticky; top: 0px; z-index: 20;">
            <v-toolbar-title class="white--text">Почасовая работа</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="close()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="px-4 pb-0">
            <v-row>
              <v-col :cols="!getHourPayWorkMasters ? 6 : 12">
                <v-text-field
									:disabled="!!this.formData"
                  :readonly="readonly"
                  color="#e0a677"
                  label="Дата"
                  :value="fieldDateText"
                  @click="selectDate()"
                  placeholder="Дата"
                />
              </v-col>
              <v-col cols="12" v-if="!getHourPayWorkMasters || user.role === 'prodDir'">
                <v-text-field
                  :readonly="readonly"
                  color="#e0a677"
                  v-model="form.numberOfHours"
                  type="number"
                  label="Часы"
                  placeholder="2"
                  suffix="час."
                />
              </v-col>
              <v-col cols="12" v-if="getHourPayWorkMasters && user.role != 'prodDir'" class="py-0">
                <v-row class="pa-0">
                  <v-col cols="6" class="pt-0">
                    <v-text-field
                      :readonly="readonly"
                      color="#e0a677"
                      v-model="form.numberOfHoursOnManufactory"
                      type="number"
                      label="На производстве"
                      placeholder="1"
                      suffix="час."
                    />
                  </v-col>
                  <v-col cols="6" class="pt-0">
                    <v-text-field
                      :readonly="readonly"
                      color="#e0a677"
                      v-model="form.numberOfHoursOnMontage"
                      type="number"
                      label="На монтаже"
                      placeholder="1"
                      suffix="час."
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <div v-for="(task, task_index) in form.tasks" :key="task_index" class="d-flex">
                  <v-textarea
                    :readonly="readonly"
                    color="#e0a677"
                    v-model="task.value"
                    label="Что сделал"
                    rows="3"
                  />
                  <div v-if="!readonly" style="padding-bottom: 19px;margin-left: 5px;">
                    <div 
                      style="
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: wrap;
                        width: 35px;
                        background: #ebebeb;
                      "
                    >
                      <v-btn icon color="blue" @click="addFieldTask()">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                      <v-btn v-if="form.tasks.length > 1" icon color="red" @click="removeFieldTask(task_index)">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="pb-5">
            <v-btn 
              v-if="formData"
              color="red"
              text
              @click="save({ ...form, del: true })"
            >
              Удалить
            </v-btn>
            <v-spacer />
            <v-btn 
              color="orange"
              text
              @click="save(form)"
            >
              Сохранить
            </v-btn>
            <v-spacer v-if="!formData" />
          </v-card-actions>
        </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import SelectDate from '@/components/Modals/SelectDate'

export default {
  props: {
    formData: {
      type: Object,
      default: null
    },
    taskUser: {
      type: Object,
      delete: null
    }
  },
  data: () => ({
    dialog: true,
    updateKey: 0,
    form: {
      date: new Date().toISOString().substr(0, 10),
      numberOfHours: 0,
      numberOfHoursOnManufactory: 0,
      numberOfHoursOnMontage: 0,
      tasks: [
        { value: '' }
      ]
    }
  }),
  created() {
    if (this.formData != null) {
      this.form.date = this.formData.date || new Date().toISOString().substr(0, 10)
      this.form.numberOfHours = this.formData.numberOfHours || 1
      this.form.numberOfHoursOnManufactory = this.formData.numberOfHoursOnManufactory || 0
      this.form.numberOfHoursOnMontage = this.formData.numberOfHoursOnMontage || 0
      this.form.tasks = this.formData.tasks || [ { value: '' } ]
    }
  },
  computed: {
    fieldDateText() {
      let date = this.form.date
      return new Date(date).toLocaleString('ru', {day: 'numeric', month: 'short' })
    },
    readonly() {
      return false
    },
    getHourPayWorkMasters() {
      return this.$store.getters['settings/getHourPayWorkMasters']
    },
    user() {
      return this.$store.getters['user/getUser']
    }
  },
  methods: {
    removeFieldTask(index) {
      this.form.tasks.splice(index, 1)
    },
    addFieldTask() {
      this.form.tasks.push({ value: '' })
    },
    async selectDate() {
      if (!this.readonly) {
        let modalDate = await this.$showModal({
          component: SelectDate,
          isPersistent: true,
          props: {
            title: 'Дата выполнения',
            selectDate: this.form.date
          }
        })
        if (modalDate) {
          this.form.date = modalDate
        }
      }
    },
    async save(data) {
      if (this.getHourPayWorkMasters && !data.del && !this.formData) {
        let checkDateTask = await this.$store.dispatch('hourlyWorks/checkDateTask', { date: new Date(data.date), user: this.taskUser })
        if (checkDateTask) {
          alert('Запись с указаной датой уже существует')
        } else {
          this.$emit('close', data)
          this.dialog = false
        }
      } else {
        this.$emit('close', data)
        this.dialog = false
      }
    },
    close() {
      this.dialog = false
      setTimeout(() => {
        this.$emit('close')
      }, 200)
    }
  }
}
</script>

<style lang="scss">
</style>